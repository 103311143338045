// ////////////////////////////////////////////////////////////////////////////
// //
// // Copyright 2023 Realm Inc.
// //
// // Licensed under the Apache License, Version 2.0 (the "License");
// // you may not use this file except in compliance with the License.
// // You may obtain a copy of the License at
// //
// // http://www.apache.org/licenses/LICENSE-2.0
// //
// // Unless required by applicable law or agreed to in writing, software
// // distributed under the License is distributed on an "AS IS" BASIS,
// // WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// // See the License for the specific language governing permissions and
// // limitations under the License.
// //
// ////////////////////////////////////////////////////////////////////////////

// import { Navigate, Outlet } from 'react-router-dom';
// import { RealmProvider, UserProvider } from '@realm/react';

// import { Task } from './models/Task';
// import { PageLayout } from './components/PageLayout';

// /**
//  * The part of the React tree having access to an authenticated user. It
//  * renders `@realm/react`'s `UserProvider` for providing the App User once
//  * authenticated and `RealmProvider` for opening a Realm.
//  */
// export function AuthenticatedApp() {
//   return (
//     // The component set as the `fallback` prop will be rendered if a user has
//     // not been authenticated. In this case, we will navigate the user to the
//     // unauthenticated route via the `Navigate` component. Once authenticated,
//     // `RealmProvider` will have access to the user and set it in the Realm
//     // configuration; therefore, you don't have to explicitly provide it here.
//     <UserProvider fallback={<Navigate to='/' />}>
//       <RealmProvider
//         schema={[Task]}
//         sync={{
//           flexible: true,
//           // To sync data to the device, we need to subscribe to our tasks.
//           initialSubscriptions: {
//             update: ((mutableSubs, realm) => {
//               mutableSubs.add(realm.objects(Task), { name: 'allTasks' });
//             }),
//           },
//         }}
//       >
//         <PageLayout>
//           <Outlet />
//         </PageLayout>
//       </RealmProvider>
//     </UserProvider>
//   );
// }

// 引入React Router的Navigate和Outlet组件。
// Navigate用于重定向，而Outlet用于渲染当前路由的子组件。
import { Navigate, Outlet } from 'react-router-dom';
// 引入Realm的Provider组件，这些组件用于实现认证和数据管理。
import { RealmProvider, UserProvider } from '@realm/react';

// 引入Task模型，这是定义数据结构的地方，用于Realm数据库。
import { StickerData } from './models/Task';
// 引入PageLayout组件，这是应用程序页面布局的组件。
import { PageLayout } from './components/PageLayout';

/**
 * AuthenticatedApp组件是应用程序中已认证用户能访问的部分。
 * 它使用了Realm的UserProvider和RealmProvider来提供认证后的用户信息
 * 和打开一个Realm数据库的能力。
 */
export function AuthenticatedApp() {
  return (
    // 使用UserProvider来检查用户是否已经认证。fallback属性提供一个未认证时渲染的组件。
    // 如果用户未认证，我们使用Navigate组件将用户重定向到登录页面。
    <UserProvider fallback={<Navigate to='/' />}>
      {/* RealmProvider组件用于打开一个Realm数据库实例。
          schema属性定义了数据库中包含的模型，这里只有Task模型。
          sync属性配置了数据库的同步选项。*/}
      <RealmProvider
        schema={[StickerData]} // 设置Realm使用的模型，这里只使用Task模型。
        sync={{
          flexible: true, // 开启灵活同步模式。
          // initialSubscriptions定义了初始化订阅，它决定了哪部分数据将被同步到本地数据库。
          initialSubscriptions: {
            update: ((mutableSubs, realm) => {
              // 这里订阅了所有Task对象，并给这个订阅命名为'allSticker'。
              mutableSubs.add(realm.objects(StickerData), { name: 'allSticker' });
            }),
          },
        }}
      >
        {/* PageLayout组件定义了页面的布局，Outlet组件则会渲染当前路由的子组件。 */}
        <PageLayout>
          <Outlet />
        </PageLayout>
      </RealmProvider>
    </UserProvider>
  );
}
