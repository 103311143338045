// 引入React Router和Realm的相关依赖。React Router用于前端路由，Realm用于与MongoDB Atlas App Services进行交互。
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AppProvider } from '@realm/react';

// 引入应用的各个页面和配置文件。
import { AuthenticatedApp } from './AuthenticatedApp';
import { ErrorPage } from './pages/ErrorPage';
import { LoginPage } from './pages/LoginPage';
import { TaskPage } from './pages/TaskPage';
import config from './atlas-app-services/config.json';
import styles from './styles/App.module.css';

// 创建一个React Router路由器，定义了路由规则和对应的组件。
const router = createBrowserRouter([
  {
    path: '/', // 当URL为根路径时，渲染LoginPage组件。
    element: <LoginPage />,
    errorElement: <ErrorPage />, // 如果在加载组件时发生错误，则渲染ErrorPage组件。
  },
  {
    element: <AuthenticatedApp />, // AuthenticatedApp组件将作为其子路由的父组件。
    children: [
      {
        path: 'tasks', // 当URL匹配'/tasks'时，渲染TaskPage组件。
        element: <TaskPage />
      }
    ]
  }
]);

/**
 * 这是根React组件，它使用`@realm/react`的`AppProvider`来初始化一个Atlas App Services应用。
 * `AppProvider`组件需要一个Atlas App的ID，这个ID在config.json文件中配置。
 * `RouterProvider`组件则用于启用React Router的路由功能。
 */
function App() {
  return (
    <div className={styles.container}> 
      <AppProvider id={config.ATLAS_APP_ID}> 
        <RouterProvider router={router} />  
      </AppProvider>
    </div>
  );
}
// function App() {
//   return (
//     <div className={styles.container}> // 使用App.module.css中的样式定义容器的样式。
//       <AppProvider id={config.ATLAS_APP_ID}> // 将Atlas App ID传递给AppProvider。
//         <RouterProvider router={router} />  // 将创建的路由器传递给RouterProvider，启用路由。
//       </AppProvider>
//     </div>
//   );
// }

export default App; // 导出App组件，以便在其他地方使用。
