////////////////////////////////////////////////////////////////////////////
//
// 版权所有 2023 Realm Inc.
//
// 根据Apache License 2.0版授权许可。
// 除非遵守许可，否则不得使用此文件。
// 可以在下面的链接获取许可证副本：
// http://www.apache.org/licenses/LICENSE-2.0
//
// 除非法律要求或书面同意，否则根据许可证分发的软件
// 是在“按原样”基础上提供的，不带任何种类的保证或条件，无论是明示的还是暗示的。
// 请参阅许可证以了解管理权限和限制。
//
////////////////////////////////////////////////////////////////////////////

// 引入NavBar组件，这通常用于应用中的导航栏。
import { NavBar } from './NavBar';
// 引入PageLayout组件的样式模块。
import styles from '../styles/PageLayout.module.css';

// 定义PageLayoutProps类型，这是一个TypeScript的类型定义，用于规范PageLayout组件的props结构。
// children属性是React.ReactNode类型，可以接收任何可以被渲染的React节点，包括字符串、数字、React元素或者这些的数组等。
type PageLayoutProps = {
  children: React.ReactNode;
};

/**
 * PageLayout组件是一个围绕Outlet的包装器，用于提供一致的布局。
 * @param {PageLayoutProps} { children } - 解构赋值，直接从props中提取children属性。
 * @return 返回一个用于布局的React元素，包含NavBar和main元素。
 */
export function PageLayout({ children }: PageLayoutProps) {
  return (
    // div元素用于包裹布局内容，使用styles.container引用CSS模块中的.container样式。
    <div className={styles.container}>
      <NavBar /> {/* 渲染NavBar组件 */}
      {/* main元素用于包裹子元素，通常是页面的主体内容。 */}
      <main>
        {children} {/* 这里渲染传递给PageLayout的children元素，即嵌套内容 */}
      </main>
    </div>
  );
}
