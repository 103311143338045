////////////////////////////////////////////////////////////////////////////
//
// Copyright 2023 Realm Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
////////////////////////////////////////////////////////////////////////////

import { FormEvent, useState } from 'react';

import styles from '../styles/AddTaskForm.module.css';

type AddTaskFormProps = {
  onSubmit: (content: string) => void;
};

/**
 * Form for adding a new task.
 */
export function AddTaskForm({ onSubmit }: AddTaskFormProps) {
  const [description, setDescription] = useState('');

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    onSubmit("");
    setDescription('');
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      {/* <input
        className={styles.input}
        type='text'
        placeholder='Add a new task'
        value={description}
        onChange={(event) => setDescription(event.currentTarget.value)}
        autoCorrect='off'     // Safari only
        autoCapitalize='none' // Safari only
      /> */}
      <button className={styles.button} type='submit'>
      <svg viewBox="-7 -7 28 28">
                <g>
                    <rect height="14.5664" opacity="0" width="14.561" x="0" y="0" />
                    <path
                        d="M0 7.2832C0 8.04053 0.617676 8.6582 1.375 8.6582L5.9082 8.6582L5.9082 13.1914C5.9082 13.9434 6.52588 14.5664 7.2832 14.5664C8.04053 14.5664 8.65283 13.9434 8.65283 13.1914L8.65283 8.6582L13.1914 8.6582C13.9434 8.6582 14.561 8.04053 14.561 7.2832C14.561 6.52588 13.9434 5.9082 13.1914 5.9082L8.65283 5.9082L8.65283 1.375C8.65283 0.623047 8.04053 0 7.2832 0C6.52588 0 5.9082 0.623047 5.9082 1.375L5.9082 5.9082L1.375 5.9082C0.617676 5.9082 0 6.52588 0 7.2832Z"
                        fill="#000000" fill-opacity="0.65" />
                </g>
            </svg>
      </button>
    </form>
  );
}
