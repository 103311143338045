////////////////////////////////////////////////////////////////////////////
//
// 版权所有 2023 Realm Inc.
//
// 根据Apache License 2.0版授权许可。
// 除非遵守许可，否则不得使用此文件。
// 可以在下面的链接获取许可证副本：
// http://www.apache.org/licenses/LICENSE-2.0
//
// 除非法律要求或书面同意，否则根据许可证分发的软件
// 是在“按原样”基础上提供的，不带任何种类的保证或条件，无论是明示的还是暗示的。
// 请参阅许可证以了解管理权限和限制。
//
////////////////////////////////////////////////////////////////////////////

// 从react库中导入所需的Hooks函数
import { FormEvent, useEffect, useState } from 'react';
// 导入react-router-dom库中的Navigate组件，用于路由跳转
import { Navigate } from 'react-router-dom';
// 导入@realm/react库中相关的功能，用于处理认证
import { AuthOperationName, useApp, useEmailPasswordAuth, useAuth } from '@realm/react';

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

// 导入登录页的logo图片
import logo from '../assets/atlas-app-services.png';
// 导入登录页样式模块
import styles from '../styles/LoginPage.module.css';

/**
 * 登录和注册App服务应用的页面组件
 */
export function LoginPage() {
  // 使用useApp Hook获取应用实例
  const atlasApp = useApp();
  // 使用useEmailPasswordAuth Hook来处理注册和登录操作，并获取结果
  const { register, logIn, result } = useEmailPasswordAuth();
  const { logInWithJWT } = useAuth();
  // 使用useState Hook管理email和password的状态
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // 使用useState Hook来管理用户是想登录还是注册
  const [authRequest, setAuthRequest] = useState<'login' | 'register'>('login');

  // 自动登录功能
  // 延时1秒后自动登录
  useEffect(() => {
    const timer = setTimeout(() => {
      logIn({ email: "1", password: "" });
    }, 1000); // 延迟1秒执行

    return () => clearTimeout(timer); // 清除定时器，避免内存泄漏
  }, []); // 依赖数组为空，确保只执行一次

  // 使用useEffect Hook，在注册成功后自动登录用户
  useEffect(() => {
    if (result.operation === AuthOperationName.Register && result.success) {
      logIn({ email, password });
    }
  }, [result.operation, result.success, logIn, email, password]);

  // 如果当前用户已经登录成功，则使用<Navigate>组件重定向到任务页面
  if (atlasApp.currentUser) {
    return <Navigate to='/tasks' />;
  }

  // 处理表单提交的函数
  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    // 阻止表单的默认提交行为
    event.preventDefault();

    // 根据authRequest的值来决定是登录还是注册
    if (authRequest === 'register') {
      register({ email, password });
    } else {
      logIn({ email, password });
    }
  };

  // 处理登录和注册按钮点击的函数
  const handleButtonClicked = (event: FormEvent<HTMLButtonElement>): void => {
    // 设置authRequest的状态为按钮的value值，这个值是'login'或'register'
    setAuthRequest(event.currentTarget.value as 'login' | 'register');
  };

  // 登录页面的JSX结构
  return (
    <GoogleOAuthProvider clientId={'229028300901-mlof4eenp3f6j1qlssgodpj9ndrgaa3n.apps.googleusercontent.com'}>
      <div className={styles.container}>
        
        
        <form onSubmit={handleSubmit} className={styles.form}>
        <h2>💛 Welcome</h2>
          <input
            className={styles.input}
            type='text'
            placeholder='Email'
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
            autoCorrect='off'
            autoCapitalize='none'
          />
          <input
            className={styles.input}
            type='password'
            placeholder='Password (min. 6 chars)'
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
          />
          {/* {result.error && <p className={styles.error}>{result.error.message}</p>} */}
          {result.error && !result.error.message.includes('App not available in requested region') && <p className={styles.error}>{result.error.message}</p>}   
          <div className={styles.buttons}>
            <button
              className={styles.button}
              type='submit'
              value='login'
              onClick={handleButtonClicked}
            >
              Sign In
            </button>
          </div>
        
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            console.log('Login Success:', credentialResponse);
            // 这里可以处理登录后的逻辑，例如使用凭据登录你的应用
            logInWithJWT(credentialResponse.credential ?? "");
            
          }}
          onError={() => {
            console.log('Login Failed');
          }}
          useOneTap
        />
        </form>
      </div>
      
    </GoogleOAuthProvider>
  );
}
