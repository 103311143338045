////////////////////////////////////////////////////////////////////////////
//
// 版权所有 2023 Realm Inc.
//
// 根据Apache License 2.0版授权许可。
// 除非遵守许可，否则不得使用此文件。
// 可以在下面的链接获取许可证副本：
// http://www.apache.org/licenses/LICENSE-2.0
//
// 除非法律要求或书面同意，否则根据许可证分发的软件
// 是在“按原样”基础上提供的，不带任何种类的保证或条件，无论是明示的还是暗示的。
// 请参阅许可证以了解管理权限和限制。
//
////////////////////////////////////////////////////////////////////////////

// 引入React核心库，可以使用React的功能。
import React from 'react';
// 引入ReactDOM，这是一个提供了与DOM相关操作的库。
import ReactDOM from 'react-dom/client';

// 引入App组件，这通常是你应用的顶层组件。
import App from './App';
// 引入reportWebVitals工具函数，用于测量页面的性能。
import reportWebVitals from './reportWebVitals';
// 引入全局样式文件，这会影响你的整个应用的样式。
import './styles/global.css';

// 使用ReactDOM.createRoot方法创建一个root容器实例。
// document.getElementById('root')是获取你public/index.html中id为'root'的DOM元素。
// as HTMLElement是TypeScript的类型断言，表示确信获取到的是一个HTMLElement类型的元素。
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// 使用root实例的render方法来渲染你的App组件。
// <React.StrictMode>是一个用来检查你的应用潜在问题的辅助组件。
// 它会在开发模式下对你的应用进行额外的检查和警告，但在生产构建中不会有任何影响。
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// reportWebVitals是一个可选的性能测量函数，你可以传递一个回调函数给它。
// 例如：reportWebVitals(console.log)会在控制台打印性能测量结果。
// 你也可以将数据发送到一个分析端点来收集和分析。
// 如果你不需要性能测量，可以删除这一行代码。
// 更多信息请参考：https://bit.ly/CRA-vitals
reportWebVitals();
