////////////////////////////////////////////////////////////////////////////
//
// Copyright 2023 Realm Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
////////////////////////////////////////////////////////////////////////////

import Realm, { BSON, ObjectSchema } from 'realm';

/**
 * The `Task` data model.
 */
// export class Task extends Realm.Object {
//   _id!: BSON.ObjectId;
//   description!: string;
//   isComplete!: boolean;
//   createdAt!: Date;
//   userId!: string;

//   static schema: ObjectSchema = {
//     name: 'Task',
//     primaryKey: '_id',
//     properties: {
//       _id: { type: 'objectId', default: () => new BSON.ObjectId() },
//       description: 'string',
//       isComplete: { type: 'bool', default: false },
//       createdAt: { type: 'date', default: () => new Date() },
//       userId: 'string',
//     },
//   };
// }

export class StickerJSON {
  uuid = ""
  content = ""
  color = 0 //0~5 6colors
  font = 2 //0~4 5 font size
  userID = ""
}

export class StickerData extends Realm.Object {
  _id!: string;
  data!: string;
  uuid!: string;

  static schema: ObjectSchema = {
    name: 'StickerData',
    primaryKey: '_id',
    properties: {
      _id: { type: 'string', default: "" },
      data: { type: 'string', default: "{}" },
      uuid: { type: 'string', default: "" },
    },
  };
}