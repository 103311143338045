////////////////////////////////////////////////////////////////////////////
//
// 版权所有 2023 Realm Inc.
//
// 根据Apache License 2.0版授权许可。
// 除非遵守许可，否则不得使用此文件。
// 可以在下面的链接获取许可证副本：
// http://www.apache.org/licenses/LICENSE-2.0
//
// 除非法律要求或书面同意，否则根据许可证分发的软件
// 是在“按原样”基础上提供的，不带任何种类的保证或条件，无论是明示的还是暗示的。
// 请参阅许可证以了解管理权限和限制。
//
////////////////////////////////////////////////////////////////////////////
import React, { useState } from 'react';
// 导入Task类型，这通常是在你的模型文件中定义的TypeScript类型或接口。
import { json } from 'stream/consumers';
import type { StickerData } from '../models/Task';
// 导入TaskItem组件的样式模块。
import styles from '../styles/TaskItem.module.css';

// 定义TaskItemProps类型，这是一个TypeScript类型定义，规范了TaskItem组件的props。
type TaskItemProps = {
  sticker: StickerData; // Task对象，包含了任务相关的数据。
  onUpdateSticker: (sticker: StickerData, content: string) => void; // 切换任务完成状态的函数。
  onDelete: (sticker: StickerData) => void; // 删除任务的函数。
};

/**
 * 渲染单个任务项并提供更新或删除操作的组件。
 * @param {TaskItemProps} { task, onToggleStatus, onDelete } - 解构赋值，直接从props中提取对应的属性。
 * @return 返回表示任务项的React元素，包含描述和按钮。
 */
export function TaskItem({ sticker, onUpdateSticker, onDelete }: TaskItemProps) {
  // div元素用于包裹单个任务项。
  // 根据任务是否完成，动态添加completed样式。
  // let [text, setText] = useState<string>('');
  const [stickerContent, setStickerContent] = useState<string>(JSON.parse(sticker.data).content);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let currentText = event.target.value
    setStickerContent(currentText);
    onUpdateSticker(sticker, currentText)
    // console.log(event.target.value);
  };

let ColorValueMap = new Map([
  [0, '#F2EFAF'],
  [1, '#B0DCE5'],
  [2, '#B5E6A2'],
  [3, '#DFBCBB'],
  [4, '#AEBDE4'],
  [5, '#D9DAD9'],
]);
  let jsonData = JSON.parse(sticker.data)
  const currentColor = ColorValueMap.get(jsonData['color'])

  return (
    <div className={styles.task} style={{backgroundColor: currentColor}}>
      {/* 任务描述 */}
      <button
          className={[styles.button, styles.deleteBtn].join(' ')}
          style={{width: '18px',height: '18px'}}
          onClick={() => onDelete(sticker)}
        >
          <svg viewBox="-7 -7 28 28">
            <g>
                <rect height="14.0643" opacity="0" width="14.0454" x="3" y="3" />
                <path
                    d="M0.388736 13.6708C0.920474 14.1972 1.82819 14.1811 2.32233 13.6869L7.02204 8.98185L11.7164 13.6816C12.2266 14.1972 13.1182 14.1918 13.6446 13.6654C14.171 13.1337 14.1763 12.2475 13.6607 11.7372L8.96637 7.03752L13.6607 2.34855C14.1763 1.83293 14.1763 0.941326 13.6446 0.414959C13.1129-0.106038 12.2266-0.111409 11.7164 0.398845L7.02204 5.09318L2.32233 0.393474C1.82819-0.0952954 0.915103-0.11678 0.388736 0.409587C-0.132261 0.941326-0.116147 1.84904 0.372622 2.34318L5.0777 7.03752L0.372622 11.7426C-0.116147 12.2314-0.137632 13.1445 0.388736 13.6708Z"
                    fill="#000000" fill-opacity="0.85" />
            </g>
        </svg>
        </button>
      <textarea value={stickerContent} onChange={handleChange} />
        {/* 删除按钮，点击时调用onDelete */}
        
    </div>
  );
};
